<template>
    <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
        <el-form ref="form" label-width="100px" style="margin-top: -10px">
            <el-form-item label="名称:" required>
                <el-input size="small" maxlength="64" show-word-limit v-model="item.adName" style="width: 435px;"></el-input>
            </el-form-item>
            <el-form-item label="广告分类:" required>
                <el-select size="small" v-model="item.adType" placeholder="请选择" style="width: 435px;">
                    <el-option
                            v-for="item in adTypeList"
                            :key="item.dictValue"
                            :label="item.name"
                            :value="item.dictValue">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上传图片:" required style="height: 136px;margin-top: -9px;margin-bottom: 35px">
                <el-upload
                        style="margin-top: 12px"
                        class="e-file-upload"
                        :action="Base_url+'/admin/upload'"
                        :on-success="imageSuccess"
                        :before-upload="imageUpload"
                        :show-file-list="false"
                >
                    <el-image v-if="file"
                              style="width: 135px; height: 137px;"
                              :src="file"
                              fit="contain"></el-image>
                    <div class="e-upload--text" v-if="!file">
                        <span class="e-upload--icon el-icon-plus "></span>
                        <p>添加上传图片</p>
                    </div>
                    <p class="e-file-upload-delete" v-if="file"
                       @click.stop="file=''">
                        <i class="el-icon-delete"></i>
                    </p>
                </el-upload>
                <div style="margin-left: 20px;margin-top: 14px">
                    <p class="e-tips" style="padding-bottom: 5px;height: 25px"><span>1.尺寸：宽*高:418*188</span>
                    </p>
                    <p class="e-tips" style="padding-bottom: 5px;height: 25px">
                        <span>2.格式：支持格式:jpg/png</span></p>
                    <p class="e-tips" style="padding-bottom: 5px;height: 25px"><span>3.大小：不大于3M</span>
                    </p>
                </div>
            </el-form-item>
        </el-form>
        <div class="card-footer" style="width: 535px;">
            <el-button size="small"  @click="closeEvent">取 消</el-button>
            <el-button size="small"  type="primary" @click="saveBbiAd">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
    // import {saveBbiAd} from 'api/bbiAd'
    import {Base_url} from '../../config/index'

    export default {
        data() {
            return {
                key: '',
                file: '',
                showPrise: false,
                show: false,
                item: this.editItem,
                seaportId: this.editItem.seaportId,
                adTypeList: [],
                adUrlList: [],
                urlTypeList: [{
                    value: '1',
                    label: '是'
                }, {
                    value: '0',
                    label: '否'
                }],
                productList: [],
                contentList: [],
                informationList: [],

            }
        },
        props: {
            editItem: Object,
            seaportItem: Array,
            editVisible: Boolean
        },
        async created() {
            this.Base_url = Base_url;
            this.file = this.item.adImgUrl
            this.adTypeChange()
        },
        methods: {
            async adTypeChange(val) {
                let dataSet = await this.$get('/admin/getDictDetailList/AD_TYPE/' + Math.random());
                this.adTypeList = dataSet;
            },

            imageSuccess(res) {
                this.file = res.fileUrl
            },
            imageUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPng = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 3;

                if (!isJPG && !isPng) {
                    this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
                    return false;
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 3MB!');
                    return false;
                }
                return true;
            },
            closeEvent() {
                this.$emit('closeEdit')
            },
            async saveBbiAd() {
                let data = {
                    id: this.item.id,
                    adType: this.item.adType,
                    adName: this.item.adName,
                    adImgUrl: this.file,
                    displayTime: this.item.displayTime,
                };
                if (!this.item.adName) {
                    this.$message({
                        showClose: true,
                        message: '请输入名称',
                        type: 'error'
                    });
                    return
                }
                if (!this.item.adType) {
                    this.$message({
                        showClose: true,
                        message: '请选择广告分类',
                        type: 'error'
                    });
                    return
                }
                if (!this.file) {
                    this.$message({
                        showClose: true,
                        message: '请上传图片',
                        type: 'error'
                    });
                    return
                }

                let res = await this.$post("/admin/save_bbiAd", data);
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$emit('editSuccess')
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>

<style>

    .e-file-upload .e-upload--text{
        width: 137px;
        height: 137px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
    .e-file-upload .e-upload--text .e-upload--icon{
        margin-top: 40px;
        font-size: 25px;
        color: rgb(0,133,215);
    }

</style>
